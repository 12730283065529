.upload-form {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 70vh; /* Ocupa todo el alto de la ventana */
  }
  
  /* Estilos para el botón de carga si es necesario */
  .upload-form button {
    margin-top: 10px;
  }  

.upload-button {
background-color: transparent;
border: none; /* Elimina los bordes del botón */
cursor: pointer;
font-size: 40px;
}  

.svg-image {
    fill: #c9d2db;
    background-color: #d9dee3;
}

.alert {
  height: 30px;
  width: 245px;
  display: flex;
  transition: margin-top 0.2s ease;
  background: #1cad3680;
  border: none;
  font-size: 1rem;
  line-height: 20px;
  padding: 5px 8px;
  color: rgba(255, 255, 255, 0.6);
  border-radius: 10px;
  position: relative;
  
  button.close {
    position: absolute !important;
    margin-top: 1px !important;
    margin-right: 2px !important;
  }
}

.vertical-center {
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.vertical-center-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 200px;
}

.row-center{
  display: 'flex'; 
  justify-content: 'center';
  align-items: 'center';
}

.modal-fullscreen {
  width: 50%;
  max-width: 50%;
  margin: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

/* Estilo para centrar el contenido del modal verticalmente */
.modal-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

/* Estilo para opacar el fondo detrás del modal */
.modal-backdrop {
  opacity: 0.7; /* Ajusta la opacidad según tus preferencias */
}

/* Estilo para el fondo del modal */
.custom-modal-class {
  background-color: rgba(139, 146, 169, 0.077) /* Ajusta el valor de blur según tus preferencias */
}

/* Estilo para centrar horizontalmente el ícono */
.icon-container {
  text-align: center;
  margin: 0 auto;
}
