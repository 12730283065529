/* Dna.css */
.dna-wrapper {
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    z-index: 2000; /* Asegura que esté sobre otros elementos */
  }

  .dna-wrapper-background {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    /* background-color: rgba(255, 255, 255, 0.7); */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1900; /* Asegura que esté sobre otros elementos */
  }