html, body, #root {
    height: 100%;
    margin: 0;
  }

/* Establece la altura del 100% para el contenido de la aplicación */
#root {
    display: flex;
    flex-direction: column;
  }

/* El contenido ocupará todo el espacio restante */
.content-dashboard {
    flex-grow: 1;
    margin-left: 260px; /* Ancho del sidebar */
    margin-top: 79px; /* Altura del header u otro elemento superior */
    width: calc(100% - 260px); /* Resta el ancho del sidebar al 100% */
    height: calc(100% - 79px);
  }