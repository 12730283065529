.table-responsive {
    overflow-x: auto;
  }
  
  .my-table thead th {
    white-space: nowrap;
  }
  
  .my-table tbody td {
    white-space: nowrap;
  }
  
  /* Estilo del scrollbar */
  .table-responsive::-webkit-scrollbar {
    height: 8px;
  }
  
  .table-responsive::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.2);
    border-radius: 10px;
  }
  
  .table-responsive::-webkit-scrollbar-thumb:hover {
    background-color: rgba(0, 0, 0, 0.4);
  }
  
  .table-responsive::-webkit-scrollbar-track {
    background: transparent;
  }
  
  .table-responsive {
    scrollbar-width: thin;
    scrollbar-color: rgba(0, 0, 0, 0.2) transparent;
  }
  
  .table-responsive::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.2);
  }
  
  .table-responsive::-webkit-scrollbar-thumb:hover {
    background-color: rgba(0, 0, 0, 0.4);
  }

  .placa-highlight {
    color: black !important;
    background-color: #ffd700; /* Amarillo dorado */
    border: 2px solid black; /* Borde dorado oscuro de 2px de grosor */
    padding: 0.5rem;
    border-radius: 0.25rem;
    display: flex;
    justify-content: center; /* Centra el contenido horizontalmente */
    align-items: center; /* Centra el contenido verticalmente */
    height: 3rem; /* Altura fija */
    width: 6rem; /* Ancho fijo */
  }  
  