/* Estilo CSS para hacer el fondo transparente */
.map-container {
    background-color: rgba(0, 0, 0, 0); /* Fondo transparente */
  }

.leaflet-popup-content-wrapper, .leaflet-popup-tip {
    background-color: #000
  }
  
  .map-buttons-container {
    position: absolute;
    bottom: 10%;
    left: 50%;
    transform: translateX(-50%);
    z-index: 1000; /* Ajusta el valor según sea necesario para asegurar que esté por encima del mapa */
  }

  .continue-button-container {
    position: absolute;
    bottom: 5%;
    right: 65px;
    z-index: 1000; /* Ajusta el valor según sea necesario para asegurar que esté por encima del mapa */
  }

  .continue-button-container2 {
    position: absolute;
    bottom: 17%;
    right: 65px;
    z-index: 1000; /* Ajusta el valor según sea necesario para asegurar que esté por encima del mapa */
  }

  .continue-button-container3 {
    position: absolute;
    bottom: 5%;
    right: 65px;
    z-index: 1000; /* Ajusta el valor según sea necesario para asegurar que esté por encima del mapa */
  }
  
  .restore-button-container {
    position: absolute;
    top: 5%;
    left: 40.9%;
    z-index: 1000; /* Ajusta el valor según sea necesario para asegurar que esté por encima del mapa */
  }

  .restore-confirm-buttons-container {
    position: absolute;
    top: 5%;
    left: 50%;
    transform: translateX(-50%);
    z-index: 1000; /* Ajusta el valor según sea necesario para asegurar que esté por encima del mapa */
  }

  .exit-edit-mode-button {
    position: absolute;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 1000;
  }

  .exit-edit-mode-btn {
    padding: 10px 20px;
    font-size: 18px;
    border-radius: 50%;
  }

  .polygon-mode-message {
    position: absolute;
    top: 20px;
    left: 50%;
    transform: translateX(-50%);
    background-color: rgba(0, 0, 0, 0.85);
    color: white;
    padding: 15px 30px;
    border-radius: 8px;
    z-index: 1000;
    width: 80%;
    max-width: 600px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  }
  
  .polygon-mode-highlight {
    color: #4CAF50; /* Color verde para el título */
    font-weight: bold;
  }
  
  .message-close-btn {
    background: none;
    border: none;
    color: white;
    font-size: 24px;
    position: absolute;
    top: 0px; /* Ajustado para estar más cerca del borde */
    right: 2px; /* Ajustado para estar más cerca del borde */
    cursor: pointer;
  }
  
  .message-content {
    position: relative;
  }
  
  .message-content p {
    margin: 0;
    font-size: 14px;
    line-height: 1.5;
  }  

  .opacity-view-mode-button {
    position: absolute;
    right: 100px;
    background-color: rgb(25, 0, 255);
    color: white;
    border: none;
    border-radius: 2px;
    padding: 10px 15px;
    width: 30px;
    height: 30px;
    line-height: 30px;
    font-size: 18px;
    cursor: pointer;
    font-weight: bold;
    z-index: 1000;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Sombra para darle un toque elevado */
    transition: background-color 0.3s ease;
  }

  .exit-polygon-mode-btn {
    position: absolute;
    right: 10px;
    background-color: red; /* Color rojo para el botón de salir */
    color: white;
    border: 0px solid transparent;
    border-radius: 2px;
    padding: 6px;
    width: 33.7px;
    height: 33.7px;
    line-height: 30px;
    font-size: 13px;
    cursor: pointer;
    font-weight: bold;
    z-index: 1000;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    transition: background-color 0.3s ease;
  }
  
  .exit-polygon-mode-btn:hover {
    background-color: darkred; /* Rojo oscuro para hover */
  }  
  
  .polygon-mode-btn {
    position: absolute;
    right: 10px;
    background-color: #f7f7f7; /* Fondo claro similar al botón de editar */
    color: black; /* Ícono negro */
    border: 2.3px solid #b9b9b9; /* Borde más claro */
    border-radius: 4px; /* Bordes más redondeados */
    padding: 6px; /* Ajuste en el padding para que sea más parecido al botón de editar */
    width: 33.7px;
    height: 33.7px;
    line-height: 30px;
    font-size: 13px;
    cursor: pointer;
    font-weight: bold;
    z-index: 1000;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1); /* Sombra más ligera */
    transition: background-color 0.3s ease, box-shadow 0.3s ease;
  }
  
  .polygon-mode-btn:hover {
    background-color: #ebebeb; /* Fondo un poco más oscuro en hover */
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2); /* Sombra un poco más pronunciada en hover */
  }
  