.custom-card-collapse {
    margin-right: 20px !important;
    padding-right: 10px !important;
  }

.title-container {
    position: relative;
}

.divider-line {
    width: 100%;
    height: 1px;
    background-color: #ffffff; /* Color de la línea */
    position: absolute;
    bottom: 0;
    left: 0;
}

.filter-green{
    filter: invert(48%) sepia(79%) saturate(2476%) hue-rotate(86deg) brightness(118%) contrast(119%);
    width: 20px; 
    height: 20px;
}

.custom-tooltip {
    position: absolute;
    background-color: rgba(0, 0, 0, 0.8);
    color: #fff;
    padding: 10px; /* Ajusta el espaciado interno del Tooltip */
    border-radius: 4px;
    z-index: 9999;
    width: 200px;
    text-align: center; /* Centra el texto dentro del Tooltip */
  }
  
  .custom-tooltip-trigger {
    margin-right: 10px;
  }
  
  .custom-tooltip strong {
    font-weight: bold; /* Hace que el texto dentro de <strong> esté en negrita */
  } 
  
  .div_hover {
    background-color: #344675;
    color: white; /* Color de texto predeterminado */
  }
  
  .div_hover:hover {
    background-color: white;
    color: #344675; /* Cambiar el color del texto cuando el fondo cambia */
  }

  .selected-despacho-day {
    background-color: #216ba5 !important; /* Color del día seleccionado */
  }
  
  .despacho-day {
    background-color: #ffcccb !important; /* Color de fondo personalizado para "despacho day" */
    color: #000000 !important; /* Color de texto personalizado para "despacho day" */
  }

  .form-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  
  .calendar-container {
    margin-bottom: 20px;
  }
  
  .select-container {
    width: 200px;
  }
  
  .select-input {
    padding: 10px;
    font-size: 16px;
  }
  
  .custom-input-group .form-control {
    border: 2px solid #1d8cf8; /* Define el color del borde y su grosor */
    text-align: center;
  }
  
  .custom-input-group .form-control {
    height: 50px; /* Ajusta la altura según sea necesario */
    font-size: 0.9rem; /* Ajusta el tamaño del texto según sea necesario */
  }
  
  .custom-input-group .input-group-text {
    height: 50px; /* Asegura que el ícono tenga la misma altura que el input */
    font-size: 0.9rem; /* Ajusta el tamaño del ícono según sea necesario */
  }
  